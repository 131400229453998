import React from "react";
import "./NotIframeCatch.css";

const NotIframeCatch = () => {
  const [countdown, setCountdown] = React.useState<number>(7);

  React.useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else {
      if (window) {
        window.location.href = "https://www.zappaprotect.com";
      }
    }
  }, [countdown]);

  return (
    <div className="container">
      <div className="wrapper">
        <h1>Uh oh!</h1>
        <h2>Not sure you meant to come here.</h2>
        <span>
          Redirecting to https://www.zappaprotect.com in {countdown} second
          {countdown !== 1 && "s"}.
        </span>
      </div>
    </div>
  );
};

export default NotIframeCatch;
