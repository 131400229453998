import * as React from "react";
import "./LoadingComponent.css";

const LoadingComponent = ({
  status,
}: {
  status: "success" | "failure" | "loading";
}) => {
  return (
    <div className={`zp-loader`}>
      <div role="progressbar" className={`zp-loader-container ${status}`}>
        <span className={`${status}`}></span>
        <span className={`${status}`}></span>
        <span className={`${status}`}></span>
        <span className={`${status}`}></span>
        <span className={`${status}`}></span>
      </div>
    </div>
  );
};

export default LoadingComponent;
