export const postMessageToParent = (event: string, message?: string): void => {
  window.parent.postMessage(
    {
      source: "zappa-protect",
      payload: {
        event: event,
        ...(message && { payload: message }),
      },
    },
    "*"
  );
};

export const postMessageToZen = (data: string): boolean => {
  //Call to Zen mocked to false while we create the right pipeline to ZEN Engine

  /*return new Promise((resolve, reject) => {
    fetch(`ZEN URL`, {
        method: 'POST',
        headers: {
            "x-api-key": "EqjvObrjh63ubyvk0xwMZ1KcOXEmDRuW6WxGbAJQ",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            key: key,
            domain: window.location.hostname
        })
    })
    .then((res) => res.json())
    .then((res) => resolve(res))
    .catch((err) => reject(err))
  })*/
  return false;
}
