import "./Head.css";
import {postMessageToParent} from "../../utilities/postMessage";

const Head = () => {
  return (
    <div className="Head" data-testid="head-container">
      <div>Instructions:</div>
      <button data-testid="close-button" 
        onClick={() => postMessageToParent("abort-challenge", "success")}>
        X
      </button>
    </div>
  );
}

export default Head;
