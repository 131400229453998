import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotIframeCatch from "./components/NotIframeCatch/NotIframeCatch";
import Trigger from "./components/Trigger/Trigger";
import {Challenge} from "./components/Challenge/Challenge";
import cookie from "cookiejs";
const isInIframe = (): boolean => {
  if (window.location !== window.parent.location) {
    return true;
  } else {
    return false;
  }
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const randomChallenge = Math.floor(Math.random() * (2 - 1 + 1) + 1);
let SelectedChallenge: "button" | "tracedline" | "puzzle" = "button";
let instruction = "Drag the sprite into the placeholder section";
switch (randomChallenge) {
  case 1:
    SelectedChallenge = "button";
    instruction = "Drag the sliders so they intersect the blue line";
    break;
  case 2:
    SelectedChallenge = "puzzle";
    break;
}
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={isInIframe() ? <Trigger /> : <NotIframeCatch />}
        />
        <Route
          path="/challenge"
          element={
            isInIframe() ? (
              <Challenge
                challenge={SelectedChallenge}
                instructions={instruction}
              />
            ) : (
              <NotIframeCatch />
            )
          }
        />
        <Route
          path="/reattempt"
          element={
            isInIframe() ? (
              <Challenge
                challenge={"faceverify"}
                instructions={instruction}
                reattempt={true}
              />
            ) : (
              <NotIframeCatch />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
